import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import marked from "marked"
import PageLayout from "../components/hrbfpagelayout"
import Head from "../components/head"

export const query = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      title
      startDate(formatString: "MMMM D")
      eventEndDate: endDate(formatString: "D, YYYY")
      endDate(formatString: "MMMM D, YYYY")
      eventStartMonth: startDate(formatString: "MMMM")
      eventEndMonth: endDate(formatString: "MMMM")
      displayName
      slug
      registrationUrl
      registrationCtaText
      transparentHeader
      headerColor
      bannerLogo {
        file {
          url
        }
      }
      bannerImageLeft {
        file {
          url
        }
      }
      bannerImageRight {
        file {
          url
        }
      }
      bannerImageLeftMobile {
        file {
          url
        }
      }
      bannerImageRightMobile {
        file {
          url
        }
      }
      activities {
        title
        subtitle
        hackathon {
          trainingHeading
          trainingSubHeading
          trainingTopics {
            title
            subtitle
            time
            details {
              details
            }
          }
        }
        seoMetadata {
          title
          description {
            description
          }
        }
      }
    }
  }
`

const Training = props => {
  let registerUrl
  if (typeof window !== "undefined") {
    if (window.location.href.includes("?")) {
      let currentLocation = window.location.href.split("?")
      if (props?.data?.contentfulEvents?.registrationUrl.includes("?")) {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "&" +
          currentLocation[1]
      } else {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "?" +
          currentLocation[1]
      }
    } else {
      registerUrl = props?.data?.contentfulEvents?.registrationUrl
    }
  }

  const getMarkdownText = trainingTopicDetails => {
    if (
      trainingTopicDetails &&
      (trainingTopicDetails !== null || trainingTopicDetails !== undefined)
    ) {
      var rawMarkup = marked(trainingTopicDetails, {
        sanitize: true,
        breaks: true,
      })
      return { __html: rawMarkup }
    }
  }
  const [scrollPosition, setScrollPosition] = useState(0)
  useEffect(() => {
    const close = e => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener("scroll", close)
  })

  return (
    <PageLayout
      title={props?.data?.contentfulEvents?.displayName?.toUpperCase()}
      logo={props?.data?.contentfulEvents?.bannerLogo}
      date={
        props?.data?.contentfulEvents?.startDate === null
          ? props?.data?.contentfulEvents?.endDate
          : props?.data?.contentfulEvents?.eventStartMonth ===
            props?.data?.contentfulEvents?.eventEndMonth
          ? `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.eventEndDate}`
          : `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.endDate}`
      }
      slug={props?.data?.contentfulEvents?.slug}
      registrationUrl={registerUrl}
      headerbtn={props?.data?.contentfulEvents?.registrationCtaText}
      bannerImageLeft={props?.data?.contentfulEvents?.bannerImageLeft}
      bannerImageLeftMobile={
        props?.data?.contentfulEvents?.bannerImageLeftMobile
      }
      bannerImageRight={props?.data?.contentfulEvents?.bannerImageRight}
      bannerImageRightMobile={
        props?.data?.contentfulEvents?.bannerImageRightMobile
      }
      transparentHeader={props?.data?.contentfulEvents?.transparentHeader}
      scroll={scrollPosition}
    >
      <Head
        title={props?.data?.contentfulEvents?.activities?.seoMetadata?.title}
        description={
          props?.data?.contentfulEvents?.seoMetadata?.activities?.description
            ?.description
        }
      />
      <div className="activites_body">
        <div className="activites_back">
          <Link
            to={`/events/${props?.data?.contentfulEvents?.slug}/activities`}
          >
            &lt; &nbsp;&nbsp;Activities
          </Link>
        </div>
        <div className="activites_row">
          <h2>
            {
              props?.data?.contentfulEvents?.activities?.hackathon
                ?.trainingHeading
            }
          </h2>
          <p>
            {
              props?.data?.contentfulEvents?.activities?.hackathon
                ?.trainingSubHeading
            }
          </p>
        </div>

        {props?.data?.contentfulEvents?.activities?.hackathon?.trainingTopics?.map(
          (item, index) => {
            return (
              <div className="activites_row">
                <h5>{item?.title}</h5>
                <p className="activites_sub_title">{item?.subtitle}</p>
                <p className="activites_sub_time">{item?.time}</p>
                <div
                  dangerouslySetInnerHTML={getMarkdownText(
                    item?.details?.details
                  )}
                ></div>
              </div>
            )
          }
        )}
        <div className="activites_back">
          <span>*Exact times subject to change.</span>{" "}
        </div>
      </div>
    </PageLayout>
  )
}
export default Training
